<script>
import { useCartFunctions } from '@/composables/useCartFunctions';
import useSizeDisplay from '@/composables/useSizeDisplay';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showNumericInput: false,
      inputQuantity: '',
      quantity: this.row.quantity,
    };
  },
  watch: {
    row(val) {
      this.quantity = val.quantity;
    },
  },
  setup() {
    const { setQuantityOnRow } = useCartFunctions();
    const { websiteText } = useWebsiteTexts();
    const { getSizeName } = useSizeDisplay();

    return {
      setQuantityOnRow,
      websiteText,
      getSizeName,
    };
  },
  methods: {
    async onRemove() {
      await this.setQuantityOnRow(this.row.id, 0);
    },
    async onChange($event) {
      const quantity = $event.target.value;
      if (quantity === this.websiteText('more').value) {
        this.showNumericInput = true;
      } else {
        await this.setQuantityOnRow(this.row.id, quantity);
        this.$nextTick(() => {
          this.quantity = this.row.quantity;
        });
      }
    },
    async onSaveQuantity() {
      if (!this.isInputValid()) {
        this.inputQuantity = '';
        return;
      }
      await this.setQuantityOnRow(this.row.id, this.inputQuantity);
      this.$nextTick(() => {
        this.quantity = this.row.quantity;
      });
      this.showNumericInput = false;
      this.inputQuantity = '';
    },
    isInputValid() {
      const value = parseInt(this.inputQuantity);
      return !isNaN(value) && value > 0;
    },
    getRowImage(row) {
      return this.$toLitiumMediaUrl(row.imageId, { maxWidth: 130 });
    },
    formattedPrice() {
      const price = this.row?.unitCampaignPrice?.price
        ? (this.row.unitListPrice * this.row.quantity)
        : (this.row.unitPrice * this.row.quantity);
      if (!price) return null;
      return this.$formatPrice(price);
    },
    formattedCampaignPrice() {
      const campaignPrice = this.row?.unitCampaignPrice?.price
        ? this.row?.unitCampaignPrice?.price * this.row.quantity
        : null;
      if (!campaignPrice) return null;
      return this.$formatPrice(campaignPrice);
    },
    // keeping this computed in case they want to display names again
    campaignName() {
      return this.row?.campaignName || this.row?.unitCampaignPrice?.name || '';
    },
    getQuantities(row) {
      const quantities = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

      if (row.quantity > 10) {
        quantities.push(row.quantity);
      }
      quantities.push(this.websiteText('more').value);

      return quantities;
    },
  },
};
</script>

<template>
  <li class="shopping-cart-row">
    <div class="shopping-cart-row_thumbnail-container">
      <a :href="row.url" class="shopping-cart-row_thumbnail-link">
        <div class="shopping-cart-row_thumbnail">
          <img
            class="shopping-cart-row_thumbnail-image"
            :src="getRowImage(row)"
            :alt="row.variantName"
            v-if="row.isModelImage"
          />
          <img
            class="shopping-cart-row_thumbnail-image shopping-cart-row_thumbnail-image-contain"
            :src="getRowImage(row)"
            :alt="row.variantName"
            v-if="!row.isModelImage"
          />
        </div>
      </a>
    </div>

    <div class="shopping-cart-row_details">
      <div>
        <div class="shopping-cart-row_product-name-price">
          <div class="shopping-cart-row_product-name-container">
            <div class="shopping-cart-row_product-name">{{ row.variantName }}</div>
            <div class="shopping-cart-row_color-size">{{ row.color }}, {{ getSizeName(row.size) }}</div>
            <div class="shopping-cart-row_article-number">
              {{ websiteText('artnumber').value }} {{ row.articleNumber }}
            </div>
          </div>
          <div class="shopping-cart-row_product-price-container">
            <div
              class="shopping-cart-row_product-price"
              :class="{'shopping-cart-row_product-price--disabled': formattedCampaignPrice()}"
            >{{ formattedPrice() }}</div>
            <div class="shopping-cart-row_product-campaign-price">{{ formattedCampaignPrice() }}</div>
          </div>
        </div>
      </div>

      <div class="shopping-cart-row_quantity-container">
        <div class="shopping-cart-row_quantity" v-if="!showNumericInput">
          <select
            class="shopping-cart-row_quantity-field"
            @change="onChange($event)"
            data-cy="quickcart-quantity"
            v-model="quantity"
          >
            <option
              v-for="q in getQuantities(row)"
              :label="q"
              :value="q"
              :selected="row.quantity == q"
            >{{ q }}</option>
          </select>
        </div>

        <div v-if="showNumericInput">
          <input
            type="number"
            v-model="inputQuantity"
            class="shopping-cart-row_quantity-numeric"
            data-cy="quickcart-quantity-numeric"
          />
          <a
            @click="onSaveQuantity()"
            class="shopping-cart-row_quantity-save"
            data-cy="quickcart-quantity-save"
          >
            {{ websiteText('ok').value }}
          </a>
        </div>

        <a
          href="#"
          class="shopping-cart-row_delete"
          @click="onRemove()"
          data-cy="quickcart-remove"
        >
          {{ websiteText('removelinktext').value }}
        </a>
      </div>
    </div>
  </li>
</template>

<style>
.shopping-cart-row {
  display: flex;
  align-items: stretch;
  padding: 20px 0;
  position: relative;
  border-bottom: 1px solid var(--color-neutral-05);
}

.shopping-cart-row_thumbnail-container {
  position: relative;
  width: 25%;
  max-width: 130px;
}

.shopping-cart-row_thumbnail-container:has(.shopping-cart-row_thumbnail-link:focus-visible) {
  outline: 1px solid black;
  outline-offset: -1px;
}

.shopping-cart-row_thumbnail {
  padding-top: 133%;
}

.shopping-cart-row_thumbnail-image {
  object-fit: cover;
  object-position: 0 10%;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.shopping-cart-row_thumbnail-image-contain {
  object-fit: contain;
  object-position: 50%;
}

.shopping-cart-row_details {
  width: 100%;
  padding: 0 0 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shopping-cart-row_product-name-price {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.shopping-cart-row_product-name-container {
  line-height: 24px;
}

.shopping-cart-row_product-name {
  font-family: var(--font-body);
  color: var(--color-coldGrey200);
  display: inline-block;
}

.shopping-cart-row_product-price-container {
  text-align: right;
  line-height: 24px;
  color: var(--color-black);
}

.shopping-cart-row_product-price {
  font-family: var(--font-header);
}

.shopping-cart-row_product-price--disabled {
  font-family: var(--font-body);
  text-decoration: line-through;
}

.shopping-cart-row_product-campaign-price {
  color: var(--color-discount);
  font-family: var(--font-body);
}

.shopping-cart-row_product-campaign-name {
  font-size: 13px;
}

.shopping-cart-row_color-size {
  font-family: var(--font-body);
  color: var(--color-grey450);
  font-size: 14px;
}

.shopping-cart-row_article-number {
  font-size: 14px;
  color: var(--color-grey450);
  display: none;
}

.shopping-cart-row_quantity-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
}

.shopping-cart-row_quantity-field {
  font-family: var(--font-body);
  background-image: url(/icons/icon_downarrow.svg);
  background-position: 90%;
  background-size: 14px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 38px;
  width: 76px;
  margin: 0;
  padding: 4px 4px 4px 12px;
  background-color: var(--color-grey990);
}

select.shopping-cart-row_quantity-field:hover {
  background-color: var(--color-grey980);
}

input[type="number"].shopping-cart-row_quantity-numeric {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-grey500);
  padding: 0.5rem;
  font-size: 0.875rem;
  font-family: var(--font-body);
  background-color: var(--color-grey990);
  color: var(--color-blackTransparent);
  line-height: normal;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  height: 38px;
  width: 76px;
  margin: 0;
  text-align: right;
}

input.shopping-cart-row_quantity-numeric:hover,
input.shopping-cart-row_quantity-numeric:focus {
  border-color: var(--color-grey500);
}

.shopping-cart-row_delete {
  display: block;
  text-decoration: none;
  align-self: flex-end;
  border-bottom: 1px solid var(--color-black);
}

a.shopping-cart-row_delete:hover,
a.shopping-cart-row_delete:focus {
  color: var(--color-coldGrey400);
  border-color: var(--color-coldGrey400);
}

.shopping-cart-row_quantity-save {
  padding: 9px 10px;
  font-family: var(--font-header);
  font-size: 16px;
  background: var(--color-black);
  color: var(--color-white);
  text-transform: uppercase;
  text-align: center;
}

.shopping-cart-row_quantity-save:hover {
  color: var(--color-white);
}

@media (--tabletAndDesktop) {
  .shopping-cart-row_color-size {
    color: var(--color-coldGrey200);
  }

  .shopping-cart-row_article-number {
    display: block;
  }

  .shopping-cart-row_details {
    width: 75%;
  }
}
</style>
